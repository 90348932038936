import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Button} from 'antd';
import {prependTwoZero} from '../../Utils/TimeUtil';

const pages = 150;

function AdminAnnualFeeNotifyPreview(props) {
  const {params, annualFeeNotifyRecords: records} = props;
  const {sender} = JSON.parse(params.query);
  const [config] = useOutlet('config');

  const [selectedRecords, setSelectedRecords] = React.useState(null);
  const [isExpand, setIsExpand] = React.useState(false);

  React.useEffect(() => {
    if (Array.isArray(records) && records.length > 0) {
      setSelectedRecords(records.filter((_, i) => i + 1 <= pages));
    }
  }, [records]);

  if (!selectedRecords) {
    return <Wrapper />;
  }

  if (Array.isArray(selectedRecords) && selectedRecords.length === 0) {
    return (
      <Wrapper>
        <div className="empty-container">查無資料</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="sidebar">
        <div className="title">
          <div>
            <span>共 {records.length} 筆</span>
            {records.length > 1 && isExpand && (
              <Button
                size="small"
                type="primary"
                style={{margin: 10, marginLeft: 10}}
                onClick={() => setIsExpand(false)}>
                收合
              </Button>
            )}
            {!isExpand && (
              <Button
                size="small"
                type="primary"
                style={{margin: 5, marginLeft: 10}}
                onClick={() => setIsExpand(true)}>
                展開
              </Button>
            )}
          </div>
        </div>

        {isExpand &&
          Array.apply(null, Array(Math.ceil(records.length / pages)))
            .map((_, i) => i)
            .map((_, n) => {
              const text =
                n === 0
                  ? ` ${n + 1}-${
                      +1 !== Math.ceil(records.length / pages)
                        ? (n + 1) * pages
                        : records.length
                    } 筆`
                  : ` ${n * pages + 1}-${
                      n + 1 !== Math.ceil(records.length / pages)
                        ? (n + 1) * pages
                        : records.length
                    } 筆`;

              return (
                <Button
                  key={n}
                  style={{margin: 5}}
                  size="small"
                  onClick={() => {
                    setSelectedRecords(null);

                    const nextSelectedRecords = records.filter((_, d) => {
                      return d + 1 <= pages * (n + 1) && d + 1 > pages * n;
                    });

                    setSelectedRecords(nextSelectedRecords);
                  }}>
                  顯示
                  {text}
                </Button>
              );
            })}
      </div>

      {sender === 'on-member-annual' &&
        selectedRecords.map((r) => onMemberAnnualTemplate(r, config))}
      {sender === 'on-member-december' &&
        selectedRecords.map((r) => onMemberDecemberTemplate(r, config))}
      {sender === 'on-member-march' &&
        selectedRecords.map((r) => onMemberMarchTemplate(r, config))}
      {sender === 'on-member-april' &&
        selectedRecords.map((r) => onMemberAprilTemplate(r, config))}
      {sender === 'on-member-may' &&
        selectedRecords.map((r) => onMemberMayTemplate(r, config))}
    </Wrapper>
  );
}

const onMemberAnnualTemplate = (r, config) => {
  return (
    <div className="page">
      <div class="cover">
        <div class="contact">
          <div>台北律師公會</div>
          <div>台北市中正區羅斯福路一段7號9樓(裕民大樓)</div>
          <div>電 話：(02)2351-5071</div>
        </div>
        <div class="text-center big-text">{r.address}</div>
        <div class="text-center big-text">{r.name} 律師 收</div>
      </div>
      <div class="content">
        <div>受文者：{r.name} 律師</div>
        <div>
          主旨：敬請　貴會員依說明二之繳費辦法及所列⾦額繳納常年會費，以利會務運作。
        </div>
        <div>說明：</div>
        <ol class="list">
          <li>
            本會承蒙
            貴會員之熱⼼指導與參與，得以順利推展會務，謹此致上⼗⼆萬分之謝意。
          </li>
          <li>
            依本會章程及相關決議，會員應按⽉繳納常年會費，⼀般會員為新台幣（下同）陸佰元，特別會員為肆佰元，外國法事務律師為陸佰元，但⼀般會員加入公會⼗年以上且年滿六⼗五歲之會員，常年會費減半繳納；加入公會⼗五年以上且年滿七⼗歲之會員，免予繳納；加入公會合計未滿三年且三⼗歲以下之會員，常年會費亦減半繳納。謹請
            貴會員於接獲本繳費通知後，撥冗依說明三所⽰繳費⽅式繳納常年會費。若有疑問請洽（02）2351-5071分機13，承辦⼈員可提供說明服務。
          </li>
          <li>
            繳費辦法：
            <br />
            會員自行於本會網站（
            <a target="_blank" href="https://www.tba.org.tw/" rel="noreferrer">
              www.tba.org.tw
            </a>
            ）登入線上刷卡或列印繳款單繳納，說明如下：會員⾄台北律師公會網站，以您的帳號密碼登入，進入『會員服務-會員主頁』，於『會費繳納』中，查閱未繳紀錄，點選繳費⽅式。
            繳納方式如下：
            <ol>
              <li>『信用卡繳款』。（免手續費）</li>
              <li>
                『超商繳款』：列印繳款單⾄各地統⼀、全家、OK及萊爾富等超商均受理。
              </li>
              <li>
                『郵政劃撥』：⼾名：社團法⼈台北律師公會，劃撥帳號：15228739。（請務必於摘要欄填寫繳款⼈資訊以利沖帳。）
              </li>
            </ol>
            <br />
            會員可以依方便性，選擇不同的繳費方式。繳費單之收執聯，繳費後請自行保管1年，以確保個人權益。
          </li>
          <div dangerouslySetInnerHTML={{__html: r.calculate_table}} />
          <div class="total text-center">小計：{r.total}</div>
        </ol>
      </div>
      <div class="sender">
        <div class="text-right">台北律師公會理事長 {config?.chairman}</div>
        <div class="text-right">{`${new Date().getFullYear()} 年 ${prependTwoZero(
          new Date().getMonth() + 1,
        )} 月 ${prependTwoZero(new Date().getDate())} 日`}</div>
      </div>
    </div>
  );
};

const onMemberDecemberTemplate = (r, config) => {
  return (
    <div className="page">
      <div class="cover">
        <div class="contact">
          <div>台北律師公會</div>
          <div>台北市中正區羅斯福路一段7號9樓(裕民大樓)</div>
          <div>電 話：(02)2351-5071</div>
        </div>
        <div class="text-center big-text">{r.address}</div>
        <div class="text-center big-text">{r.name} 律師 收</div>
      </div>
      <div className="content">
        <div>受文者：{r.name} 律師</div>
        <div>
          主旨：經查您的常年會費已逾期，為確保
          您的會員權益，敬請盡速依說明二之繳費辦法及所列⾦額繳納常年會費，以利會務運作。
        </div>
        <div>說明：</div>
        <ol class="list">
          <li>
            依本會常年會費暨跨區執業費用開徵及催收作業準則規定：會員欠繳會費經催繳後，仍未依限繳納者，得就欠繳會費之會員提祕書處工作會報按民事訴訟法督促程序辦理聲請核發支付命令。提醒
            貴會員務必撥冗依說明三所列繳費方式繳納常年會費，若逾期未蒙繳納，本會將依前揭作業準則規定，按民事訴訟法督促程序訴請
            貴會員繳納積欠之會費。
          </li>
          <li>
            依本會章程及相關決議，會員應按⽉繳納常年會費，⼀般會員為新台幣（下同）陸佰元，特別會員為肆佰元，外國法事務律師為陸佰元，但⼀般會員加入公會⼗年以上且年滿六⼗五歲之會員，常年會費減半繳納；加入公會⼗五年以上且年滿七⼗歲之會員，免予繳納；加入公會合計未滿三年且三⼗歲以下之會員，常年會費亦減半繳納。謹請
            貴會員於接獲本繳費通知後，撥冗依說明三所⽰繳費⽅式繳納常年會費。若有疑問請洽（02）2351-5071分機13，承辦⼈員可提供說明服務。
          </li>
          <li>
            繳費辦法：
            <br />
            會員⾃⾏於本會網站（
            <a target="_blank" href="https://www.tba.org.tw/" rel="noreferrer">
              www.tba.org.tw
            </a>
            ）登入線上刷卡或列印繳款單繳納，說明如下：會員⾄台北律師公會網站，以您的帳號密碼登入，進入『會員服務-會員主頁』，於『會費繳納』中，查閱未繳紀錄，點選繳費⽅式。
            <br />
            繳納方式如下：
            <ol>
              <li>『信⽤卡繳款』。（免⼿續費）</li>
              <li>
                『超商繳款』：列印繳款單⾄各地統⼀、全家、OK及萊爾富等超商均受理。
              </li>
              <li>
                『郵政劃撥』：⼾名：社團法⼈台北律師公會，劃撥帳號：15228739。（請務必於摘要欄填寫繳款⼈資訊以利沖帳。）
              </li>
            </ol>
            <br />
            會員可以依方便性，選擇不同的繳費方式。繳費單之收執聯，繳費後請自行保管1年，以確保個人權益。
          </li>
          <div dangerouslySetInnerHTML={{__html: r.calculate_table}} />
          <div class="total text-center">小計：{r.total}</div>
        </ol>
      </div>
      <div class="sender">
        <div class="text-right">台北律師公會理事長 {config?.chairman}</div>
        <div class="text-right">{`${new Date().getFullYear()} 年 ${prependTwoZero(
          new Date().getMonth() + 1,
        )} 月 ${prependTwoZero(new Date().getDate())} 日`}</div>
      </div>
    </div>
  );
};

const onMemberMarchTemplate = (r, config) => {
  return (
    <div className="page">
      <div class="cover">
        <div class="contact">
          <div>台北律師公會</div>
          <div>台北市中正區羅斯福路一段7號9樓(裕民大樓)</div>
          <div>電 話：(02)2351-5071</div>
        </div>
        <div class="text-center big-text">{r.address}</div>
        <div class="text-center big-text">{r.name} 律師 收</div>
      </div>
      <div className="content">
        <div>受文者：{r.name} 律師</div>
        <div>
          主旨：經查您的常年會費已逾期，為確保
          您的會員權益，敬請盡速依說明二之繳費辦法及所列⾦額繳納常年會費，以利會務運作。
        </div>
        <div>說明：</div>
        <ol class="list">
          <li>
            貴律師應繳常年會費經本會催繳仍未蒙繳納，後續如仍未繳納即有訴訟、強制執行之程序（本會已取得部份年度常年會費之執行名義），謹請於文到後儘速辦理繳納事宜（請優先協助處理較早期之會費），以利會務之運作。
          </li>
          <li>
            依本會章程及相關決議，會員應按⽉繳納常年會費，⼀般會員為新台幣（下同）陸佰元，特別會員為肆佰元，外國法事務律師為陸佰元，但⼀般會員加入公會⼗年以上且年滿六⼗五歲之會員，常年會費減半繳納；加入公會⼗五年以上且年滿七⼗歲之會員，免予繳納；加入公會合計未滿三年且三⼗歲以下之會員，常年會費亦減半繳納。謹請
            貴會員於接獲本繳費通知後，撥冗依說明三所⽰繳費⽅式繳納常年會費。若有疑問請洽（02）2351-5071分機13，承辦⼈員可提供說明服務。
          </li>
          <li>
            繳費辦法：
            <br />
            會員⾃⾏於本會網站（
            <a target="_blank" href="https://www.tba.org.tw/" rel="noreferrer">
              www.tba.org.tw
            </a>
            ）登入線上刷卡或列印繳款單繳納，說明如下：會員⾄台北律師公會網站，以您的帳號密碼登入，進入『會員服務-會員主頁』，於『會費繳納』中，查閱未繳紀錄，點選繳費⽅式。
            <br />
            繳納方式如下：
            <ol>
              <li>『信⽤卡繳款』。（免⼿續費）</li>
              <li>
                『超商繳款』：列印繳款單⾄各地統⼀、全家、OK及萊爾富等超商均受理。
              </li>
              <li>
                『郵政劃撥』：⼾名：社團法⼈台北律師公會，劃撥帳號：15228739。（請務必於摘要欄填寫繳款⼈資訊以利沖帳。）
              </li>
            </ol>
            <br />
            會員可以依方便性，選擇不同的繳費方式。繳費單之收執聯，繳費後請自行保管1年，以確保個人權益。
          </li>
          <div dangerouslySetInnerHTML={{__html: r.calculate_table}} />
          <div class="total text-center">小計：{r.total}</div>
        </ol>
      </div>
      <div class="sender">
        <div class="text-right">台北律師公會理事長 {config?.chairman}</div>
        <div class="text-right">{`${new Date().getFullYear()} 年 ${prependTwoZero(
          new Date().getMonth() + 1,
        )} 月 ${prependTwoZero(new Date().getDate())} 日`}</div>
      </div>
    </div>
  );
};

const onMemberAprilTemplate = (r, config) => {
  return <div className="page">四月範本</div>;
};

const onMemberMayTemplate = (r, config) => {
  return <div className="page">五月範本</div>;
};

const Wrapper = styled.div`
  font-size: 14px;

  .empty-container {
    padding: 20px;
    text-align: center;
  }

  .cover {
    padding-bottom: 30px;
    border-bottom: 1px dashed #000000;
  }

  .total {
  }

  .contact {
    padding-bottom: 10px;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .big-text {
    font-size: 16px;
  }

  .content {
    padding-top: 20px;
  }

  .list {
    list-style: cjk-ideographic;
  }

  .table-list {
    margin-top: 20px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-bottom: 1px dashed #000000;
  }

  .total {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sidebar {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;

    padding: 20px;
  }
`;

export default AdminAnnualFeeNotifyPreview;
