import React from 'react';
import styled from 'styled-components';
import {
  renderQueryInformation,
  generateExportPreviewHeaders,
  generateExportPreviewRows,
} from '../../Utils/OrderExportUtil';

function OrderExportPreview(props) {
  const {queryParams, total, records} = props;
  const headers = generateExportPreviewHeaders(queryParams);
  const rows = generateExportPreviewRows(queryParams, records);

  return (
    <Wrapper>
      <div>
        <table>
          <tr>
            <th className="title" colSpan={`${headers.length}`}>
              {queryParams.qType}繳款明細
            </th>
          </tr>
          <tr>
            <td colSpan={`${headers.length}`}>
              {renderQueryInformation(queryParams)}
            </td>
          </tr>
          <tr>
            {headers.map((h) => (
              <th>{h}</th>
            ))}
          </tr>
          {rows.map((row) => (
            <tr>
              {row.map((d, index) => {
                if (row.length < headers.length) {
                  const colSpan =
                    index === 0
                      ? Math.floor(headers.length / 2)
                      : Math.ceil(headers.length / 2);
                  const textAlign = index === 0 ? 'right' : 'left';

                  return (
                    <td colSpan={colSpan} style={{textAlign}}>
                      {d}
                    </td>
                  );
                }
                return <td>{d}</td>;
              })}
            </tr>
          ))}
        </table>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 10px;

  table {
    width: 100%;
    border: 1px solid #000000;
    background-color: #ffffff;
  }

  th.title {
    border: none;
    font-size: 16px;
  }

  th {
    text-align: center;
    padding: 4px;
    border: 1px solid #000000;
  }

  td {
    width: 100px;
    text-align: left;
    padding: 4px;
    border: 1px solid #000000;
    overflow-wrap: break-word;
  }
`;

export default OrderExportPreview;
