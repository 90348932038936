import React from 'react';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

function prependTwoZero(value) {
  return ('00' + value.toString()).slice(-2);
}

function ProductConsentPreview(props) {
  const {record} = props;
  const [users, setUsers] = React.useState(null);
  const [config] = useOutlet('config');

  const havePeople =
    record &&
    record.session &&
    Array.isArray(record.session.people) &&
    record.session.people.length > 0;
  const haveUsers =
    record &&
    record.session &&
    Array.isArray(record.session.people) &&
    record.session.people.some((person) => !!person.user);

  React.useEffect(() => {
    if (haveUsers) {
      async function fetchUsers(ids) {
        try {
          setUsers(
            await JStorage.fetchAllDocuments(
              'user_profile',
              {
                owner: {$in: ids},
              },
              null,
              {
                owner: 1,
                name: 1,
              },
            ),
          );
        } catch (err) {
          console.warn(err);
        }
      }

      const userIds = record.session.people
        .filter((p) => p.user)
        .map((p) => p.user);

      fetchUsers(userIds);
    }
  }, [haveUsers, record?.session]);

  return (
    <Wrapper>
      {havePeople &&
        record.session.people.map((person) => {
          if (person.user) {
            const targetUser = users?.find(
              (user) => user.owner === person.user,
            );

            return (
              <div className="page">
                <h2 className="title">社團法人台北律師公會 收據</h2>
                <div className="text">姓名：{targetUser?.name}</div>
                <div className="text">
                  授課方式： ☐ 現場（直播）授課／演講 ◻
                  專錄課程（即同意製為授課影片）
                </div>
                <div className="text">
                  同意課程：◻同意製為授課影片 ◻同意製為授課錄音檔
                </div>
                <div className="text">◻不同意製為授課影片及錄音檔</div>
                <div className="text">
                  本人茲確認收到社團法人台北律師公會支付
                  <b className="highlight">
                    ({record.serial_number}) {record.name}
                  </b>
                  演講費新台幣（下同）<b className="highlight">{person.pay}</b>
                  元及授權費（如有）◻12個月20,000元或 ◻
                  24個月25,000元；代扣取補充保費
                  <label className="blank" />
                  元，實支新台幣 <label className="blank" />
                  元整。
                </div>
                <table className="has-border">
                  <tr>
                    <th>匯款帳戶：</th>
                    <td>
                      {person.bank_code}
                      {person.bank_name ? (
                        <span>
                          <label className="short-blank" />
                          {person.bank_name}
                        </span>
                      ) : (
                        <span>
                          <label className="blank" />
                          <label className="blank" />
                          銀行(郵局)
                          <label className="blank" />
                          <label className="blank" />
                          分行
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>帳號：</th>
                    <td>{person.bank_account || ''}</td>
                  </tr>
                  <tr>
                    <th>戶名：</th>
                    <td>{person.bank_account_name || ''}</td>
                  </tr>
                </table>

                <h2 className="title">著作權授權同意書</h2>
                <div className="text">
                  本人（下稱「甲方」）同意社團法人台北律師公會（下稱「乙方」）就本次在職進修課程內容：
                </div>
                <ol>
                  <li className="text">
                    如授課方式為現場（直播）授課／演講，同意乙方及其會員/學員得基於進修之目的使用、儲存及重製該授課演講之講義、簡報或其他參考資料。同意乙方得於授課/演講中進行全程錄音及以上述方法進行儲存及重製，以供會員/學員補課使用。除上開演講費外，乙方就前開授權無須另行支付費用。
                  </li>
                  <li className="text">
                    如同意課程製為授課影片或錄音檔：
                    <ol type="a">
                      <li className="text">
                        將該授課/演講之講義、簡報或其他參考資料授權乙方得於授課/演講中進行全程錄音、錄影，並得以上述方法進行儲存及重製。除上開演講費及授權費外，乙方就前開授權無須另行支付費用。
                      </li>
                      <li>
                        自授課影片或錄音檔上架日起算◻12個月或◻24個月內，乙方得於乙方指定之場所、網站公開播放及公開傳輸甲方授課/演講之錄影、錄音內容或其重製內容，並於乙方發行之刊物紀錄、重製、編輯甲方授課/演講之錄影、錄音內容，並散布該重製物或編輯物。如乙方認為有延長公開播放及公開傳輸之必要，甲方同意在合理期間內，乙方得延長公開播放及公開傳輸之期限。
                      </li>
                    </ol>
                  </li>
                  <li className="text">
                    乙方尊重甲方之具名權（姓名表示權），並應依著作權法之規定適當表明甲方為本授課演講之講師，甲方不對乙方主張著作人格權。
                  </li>
                  <li className="text">
                    乙方訂立網路學習辦法，約束乙方會員不得自行錄音、錄影或重製、散布甲方之授課/演講內容。
                  </li>
                </ol>
                <table className="not-border">
                  <tr>
                    <td>
                      <div className="text">甲方： </div>
                      <div className="text">聯絡電話： </div>
                      <div className="text">身分證統一編號： </div>
                      <div className="text">戶籍所在地：</div>
                    </td>
                    <td>
                      <div className="text">乙方：社團法人台北律師公會</div>
                      <div className="text">代表人：{config?.chairman}</div>
                      <div className="text">營利事業統一編號：78379129</div>
                      <div className="text">
                        地址：台北市中正區羅斯福路一段7號9樓
                      </div>
                    </td>
                  </tr>
                </table>

                <div className="date text">
                  中華民國 {new Date().getFullYear() - 1911} 年
                  {record && record.session && record.session.date
                    ? ` ${prependTwoZero(
                        new Date(record.session.date).getMonth() + 1,
                      )} `
                    : ' --- '}
                  月
                  {record && record.session && record.session.date
                    ? ` ${prependTwoZero(
                        new Date(record.session.date).getDate(),
                      )} `
                    : ' --- '}
                  日
                </div>
              </div>
            );
          }

          return (
            <div className="page">
              <h2 className="title">社團法人台北律師公會 收據</h2>
              <div className="text">姓名：{person.name}</div>
              <div className="text">
                授課方式： ☐ 現場（直播）授課／演講 ◻
                專錄課程（即同意製為授課影片）
              </div>
              <div className="text">
                同意課程：◻同意製為授課影片 ◻同意製為授課錄音檔
              </div>
              <div className="text">◻不同意製為授課影片及錄音檔</div>
              <div className="text">
                本人茲確認收到社團法人台北律師公會支付
                <b className="highlight">
                  ({record.serial_number}) {record.name}
                </b>
                演講費新台幣（下同）<b className="highlight">{person.pay} </b>
                元及授權費（如有）◻12個月20,000元或 ◻
                24個月25,000元；代扣取補充保費
                <label className="blank" />
                元，實支新台幣 <label className="blank" />
                元整。
              </div>
              <table className="has-border">
                <tr>
                  <th>匯款帳戶：</th>
                  <td>
                    {person.bank_code}
                    {person.bank_name ? (
                      <span>
                        <label className="short-blank" />
                        {person.bank_name}
                      </span>
                    ) : (
                      <span>
                        <label className="blank" />
                        <label className="blank" />
                        銀行(郵局)
                        <label className="blank" />
                        <label className="blank" />
                        分行
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>帳號：</th>
                  <td>{person.bank_account || ''}</td>
                </tr>
                <tr>
                  <th>戶名：</th>
                  <td>{person.bank_account_name || ''}</td>
                </tr>
              </table>

              <h2 className="title">著作權授權同意書</h2>
              <div className="text">
                本人（下稱「甲方」）同意社團法人台北律師公會（下稱「乙方」）就本次在職進修課程內容：
              </div>
              <ol>
                <li className="text">
                  如授課方式為現場（直播）授課／演講，同意乙方及其會員/學員得基於進修之目的使用、儲存及重製該授課演講之講義、簡報或其他參考資料。同意乙方得於授課/演講中進行全程錄音及以上述方法進行儲存及重製，以供會員/學員補課使用。除上開演講費外，乙方就前開授權無須另行支付費用。
                </li>
                <li className="text">
                  如同意課程製為授課影片或錄音檔：
                  <ol type="a">
                    <li className="text">
                      將該授課/演講之講義、簡報或其他參考資料授權乙方得於授課/演講中進行全程錄音、錄影，並得以上述方法進行儲存及重製。除上開演講費及授權費外，乙方就前開授權無須另行支付費用。
                    </li>
                    <li>
                      自授課影片或錄音檔上架日起算◻12個月或◻24個月內，乙方得於乙方指定之場所、網站公開播放及公開傳輸甲方授課/演講之錄影、錄音內容或其重製內容，並於乙方發行之刊物紀錄、重製、編輯甲方授課/演講之錄影、錄音內容，並散布該重製物或編輯物。如乙方認為有延長公開播放及公開傳輸之必要，甲方同意在合理期間內，乙方得延長公開播放及公開傳輸之期限。
                    </li>
                  </ol>
                </li>
                <li className="text">
                  乙方尊重甲方之具名權（姓名表示權），並應依著作權法之規定適當表明甲方為本授課演講之講師，甲方不對乙方主張著作人格權。
                </li>
                <li className="text">
                  乙方訂立網路學習辦法，約束乙方會員不得自行錄音、錄影或重製、散布甲方之授課/演講內容。
                </li>
              </ol>
              <table className="not-border">
                <tr>
                  <td>
                    <div className="text">甲方： </div>
                    <div className="text">聯絡電話： </div>
                    <div className="text">身分證統一編號： </div>
                    <div className="text">戶籍所在地：</div>
                  </td>
                  <td>
                    <div className="text">乙方：社團法人台北律師公會</div>
                    <div className="text">代表人：{config?.chairman}</div>
                    <div className="text">營利事業統一編號：78379129</div>
                    <div className="text">
                      地址：台北市中正區羅斯福路一段7號9樓
                    </div>
                  </td>
                </tr>
              </table>

              <div className="date text">
                中華民國 {new Date().getFullYear() - 1911} 年
                {record && record.session && record.session.date
                  ? ` ${prependTwoZero(
                      new Date(record.session.date).getMonth() + 1,
                    )} `
                  : ' --- '}
                月
                {record && record.session && record.session.date
                  ? ` ${prependTwoZero(
                      new Date(record.session.date).getDate(),
                    )} `
                  : ' --- '}
                日
              </div>
            </div>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .page {
    & > .title {
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
    }

    & > table.has-border {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      border: 1px solid #434343;
    }

    & > table.has-border > tr > th {
      width: 15%;
      border: 1px solid #434343;
      padding: 5px;
    }

    & > table.has-border > tr > td {
      width: 85%;
      border: 1px solid #434343;
      padding: 5px;
    }

    & > table.not-border {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
    }

    & > table.not-border > tr > td {
      width: 50%;
    }

    & > ol {
      padding-left: 12px;
    }

    & > ol > li > ol {
      padding-left: 20px;
    }
  }

  .text {
    font-size: 13px;
    line-height: 24px;
  }

  .short-blank {
    margin-left: 5px;
  }

  .blank {
    margin-left: 40px;
  }

  .date {
    margin-top: 50px;
  }

  .highlight {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export default ProductConsentPreview;
