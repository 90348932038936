import React from 'react';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import styled from 'styled-components';

function ProductPayPreview(props) {
  const {record} = props;
  const [users, setUsers] = React.useState(null);

  const havePeople =
    record &&
    record.session &&
    Array.isArray(record.session.people) &&
    record.session.people.length > 0;
  const haveUsers =
    record &&
    record.session &&
    Array.isArray(record.session.people) &&
    record.session.people.some((person) => !!person.user);

  React.useEffect(() => {
    if (haveUsers) {
      async function fetchUsers(ids) {
        try {
          setUsers(
            await JStorage.fetchAllDocuments(
              'user_profile',
              {
                owner: {$in: ids},
              },
              null,
              {
                owner: 1,
                name: 1,
                id_card_number: 1,
                phone: 1,
                mail_address_city: 1,
                mail_address_zip_code: 1,
                mail_address_detail: 1,
              },
            ),
          );
        } catch (err) {
          console.warn(err);
        }
      }

      const userIds = record.session.people
        .filter((p) => p.user)
        .map((p) => p.user);

      fetchUsers(userIds);
    }
  }, [haveUsers, record?.session]);

  return (
    <Wrapper>
      {havePeople &&
        record.session.people.map((person) => {
          if (person.user) {
            const targetUser = users?.find(
              (user) => user.owner === person.user,
            );

            return (
              <div className="page">
                <h1>
                  <div>社團法人台北律師公會</div>
                  <div>勞務報酬簽收單</div>
                </h1>
                <div className="content">
                  <h2>姓名：{targetUser?.name || ''}</h2>
                  <h2>身分證字號：{targetUser?.id_card_number || ''}</h2>
                  <h2>電話：{targetUser?.phone || ''}</h2>
                  <h2>
                    地址：{targetUser?.mail_address_zip_code || ''}{' '}
                    {targetUser?.mail_address_city || ''}
                    {targetUser?.mail_address_detail || ''}
                  </h2>
                  <h2>
                    項目：{record.session.date} {record.name} {person.type}
                  </h2>
                  <div />
                </div>
                <div className="content">
                  <h2>匯款帳號</h2>
                  <h2>
                    {person.bank_code}
                    {person.bank_name ? (
                      <span>
                        <label className="short-blank" />
                        {person.bank_name}
                      </span>
                    ) : (
                      <span>
                        <label className="long-blank" />
                        銀行
                        <label className="long-blank" />
                        分行
                      </span>
                    )}
                  </h2>
                  <h2>帳號：{person.bank_account || ''}</h2>
                  <h2>戶名：{person.bank_account_name || ''}</h2>
                </div>

                <div className="content">
                  <h2>請款金額：新台幣 {person.pay} 元</h2>
                  <h3>扣繳 10% 所得稅：</h3>
                  <h3>扣繳 2.11% 補充保費：</h3>
                </div>

                <div className="sign">
                  <h2>領據人簽章：</h2>
                  <h2>日期：</h2>
                </div>

                <div>
                  備註：1、依各類所得扣繳率標準第 13
                  條規定，薪資所得超過起扣點須扣繳 5% 所得稅 ;
                  其餘各項收入超過二萬元須扣繳 10%
                  所得稅（例：執行業務報酬、租賃所得）。 2、依健保法第 31
                  條規定，超過基本工資之薪資所得須扣繳 2.11% 健保費 ;
                  其餘各項收入超過二萬元須扣繳 2.11%
                  健保費所得稅（例：執行業務報酬、租賃所得）。
                </div>
              </div>
            );
          }

          return (
            <div className="page">
              <h1>
                <div>社團法人台北律師公會</div>
                <div>勞務報酬簽收單</div>
              </h1>
              <div className="content">
                <h2>姓名：{person.name}</h2>
                <h2>身分證字號：</h2>
                <h2>電話：</h2>
                <h2>地址：</h2>
                <h2>
                  項目：{record.session.date} {record.name} {person.type}
                </h2>
                <div />
              </div>
              <div className="content">
                <h2>匯款帳號</h2>
                <h2>
                  {person.bank_code}
                  {person.bank_name ? (
                    <span>
                      <label className="short-blank" />
                      {person.bank_name}
                    </span>
                  ) : (
                    <span>
                      <label className="long-blank" />
                      銀行
                      <label className="long-blank" />
                      分行
                    </span>
                  )}
                </h2>
                <h2>帳號：{person.bank_account || ''}</h2>
                <h2>戶名：{person.bank_account_name || ''}</h2>
              </div>
              <div className="content">
                <h2>請款金額：新台幣 {person.pay} 元</h2>
                <h3>扣繳 10% 所得稅：</h3>
                <h3>扣繳 2.11% 補充保費：</h3>
              </div>
              <div className="sign">
                <h2>領據人簽章：</h2>
                <h2>日期：</h2>
              </div>

              <div>
                備註：1、依各類所得扣繳率標準第 13
                條規定，薪資所得超過起扣點須扣繳 5% 所得稅 ;
                其餘各項收入超過二萬元須扣繳 10%
                所得稅（例：執行業務報酬、租賃所得）。 2、依健保法第 31
                條規定，超過基本工資之薪資所得須扣繳 2.11% 健保費 ;
                其餘各項收入超過二萬元須扣繳 2.11%
                健保費所得稅（例：執行業務報酬、租賃所得）。
              </div>
            </div>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .page {
    & > h1 {
      margin-bottom: 30px;
      text-align: center;
    }

    & > div.content {
      margin-bottom: 25px;

      & > h2 {
        margin-bottom: 5px;
      }

      .short-blank {
        margin-left: 10px;
      }

      .long-blank {
        margin-left: 120px;
      }
    }

    & > div.sign {
      margin-bottom: 100px;

      & > h2 {
        margin-bottom: 10px;
      }
    }
  }
`;

export default ProductPayPreview;
