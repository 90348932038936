import React from 'react';
import styled from 'styled-components';
import * as Cart from 'rev.sdk.js/Actions/Cart';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function OrderPaymentCodePreview(props) {
  const {record} = props;
  const [qrCodeSrc, setQrcodeSrc] = React.useState(null);
  const [barcodesSrc, setBarcodesSrc] = React.useState(null);

  const generateQRcode = React.useCallback(async ({code_no}) => {
    if (typeof window && !window.QRCode) {
      throw new Error('cannot find QRCode');
    }

    const qrDivId = 'rev-qrcode-div';
    const qrDiv = document.createElement('div');
    qrDiv.id = qrDivId;
    document.body.appendChild(qrDiv);
    const qrcode = new window.QRCode(qrDivId);
    const qrValue = code_no;

    qrcode.makeCode(qrValue);
    await delay(200); // because QRCode lib generates code in async

    setQrcodeSrc(document.querySelector(`#${qrDivId} img`).src);
    qrDiv.remove();
  }, []);

  const generateBarcode = React.useCallback(
    async ({barcode_1, barcode_2, barcode_3}) => {
      if (typeof window && !window.JsBarcode) {
        throw new Error('cannot find JsBarcode');
      }

      const barcodeCanvasId = 'rev-barcode-canvas';
      const barcodeCanvas = document.createElement('canvas');
      barcodeCanvas.id = barcodeCanvasId;
      document.body.appendChild(barcodeCanvas);

      const nextValues = [];

      for (const item of [
        {value: barcode_1, display: barcode_1},
        {value: barcode_2, display: barcode_2},
        {value: barcode_3, display: barcode_3},
      ]) {
        window.JsBarcode(`#${barcodeCanvasId}`).CODE39(item.value).render();
        nextValues.push({
          label: item.display,
          value: item.value,
          data: barcodeCanvas.toDataURL(),
        });
      }

      setBarcodesSrc(nextValues);
      barcodeCanvas.remove();
    },
    [],
  );

  React.useEffect(() => {
    if (record && record.code_no) {
      generateQRcode({code_no: record.code_no});
    }

    if (record && record.barcode_1 && record.barcode_2 && record.barcode_3) {
      generateBarcode({
        barcode_1: record.barcode_1,
        barcode_2: record.barcode_2,
        barcode_3: record.barcode_3,
      });
    }
  }, [record, generateQRcode, generateBarcode]);

  return (
    <Wrapper>
      <div className="page">
        <h2>交易資訊</h2>
        {record &&
          record.payment_subtype === 'cvs' &&
          record.payment_redirect_detail && (
            <div>
              <div>姓名：{record.buyer_name}</div>
              <div>
                項目：
                {record.annual_year &&
                  `${record.annual_year} 年度 ${record.start_month}月 - ${record.end_month}月 `}
                {record.items[0].product.name}{' '}
              </div>
              <div>
                商店訂單編號：{record.payment_redirect_detail.MerchantOrderNo}
              </div>
              <div>
                藍新金流交易序號：{record.payment_redirect_detail.TradeNo}
              </div>
              <div>訂單金額：NT$ {record.total} 元</div>
              <div>
                <p>
                  支付方式：
                  {Cart.PAYMENT_SUBTYPE_DISPLAY[record.payment_subtype]?.label}
                </p>
                <p>{record.code_no}</p>
                <p>
                  ( 請記錄此代碼，至 7-11、全家、OK、萊爾富
                  超商多媒體機台列印繳費單。 )
                </p>
              </div>
              <div>
                取號日期：{new Date(record.created).toLocaleString('sv')}
              </div>
              <div>
                交易截止日期：{record.payment_redirect_detail.ExpireDate}{' '}
                {record.payment_redirect_detail.ExpireTime}
              </div>
              <hr />
              <p>請列印本頁，至7-11的服務機台依照指示直接掃描下方 QR code：</p>
              <p>(本 QR code 目前僅支援 7-11 ibon)</p>
              {qrCodeSrc && (
                <div className="code">
                  <img
                    src={qrCodeSrc}
                    alt="cvs-qrcode"
                    style={{width: 150, height: 150}}
                  />
                </div>
              )}
            </div>
          )}

        {record && record.payment_subtype === 'barcode' && (
          <div>
            <div>姓名：{record.buyer_name}</div>
            <div>
              項目：
              {record.annual_year &&
                `${record.annual_year} 年度 ${record.start_month}月 - ${record.end_month}月 `}
              {record.items[0].product.name}{' '}
            </div>
            <div>
              商店訂單編號：{record.payment_redirect_detail.MerchantOrderNo}
            </div>
            <div>
              藍新金流交易序號：{record.payment_redirect_detail.TradeNo}
            </div>
            <div>訂單金額：NT$ {record.total} 元</div>
            <div>
              <p>
                支付方式：
                {Cart.PAYMENT_SUBTYPE_DISPLAY[record.payment_subtype]?.label}
              </p>
            </div>
            <div>第一段條碼：{record.barcode_1}</div>
            <div>第二段條碼：{record.barcode_2}</div>
            <div>第三段條碼：{record.barcode_3}</div>
            <div>取號日期：{new Date(record.created).toLocaleString('sv')}</div>
            <div>
              交易截止日期：{record.payment_redirect_detail.ExpireDate}{' '}
              {record.payment_redirect_detail.ExpireTime}
            </div>
            <hr />
            {barcodesSrc &&
              barcodesSrc.map((b) => {
                if (b.value.length > 9) {
                  return (
                    <div className="code">
                      <img
                        alt="barcode"
                        src={b.data}
                        style={{width: 280, height: 80}}
                      />
                    </div>
                  );
                }
                return (
                  <div className="code">
                    <img
                      alt="barcode"
                      src={b.data}
                      style={{width: 230, height: 80}}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .page {
    .code {
      margin-top: 10px;
    }
  }
`;

export default OrderPaymentCodePreview;
