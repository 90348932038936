import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {message, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {fetchOrdersByPagination} from '../../Utils/OrderExportUtil';
import ProductPayPreview from './ProductPayPreview';
import ProductConsentPreview from './ProductConsentPreview';
import OrderReceiptPreview from './OrderReceiptPreview';
import OrderExportPreview from './OrderExportPreview';
import OrderPaymentCodePreview from './OrderPaymentCodePreview';
import DiscountItemReceiptPreview from './DiscountItemReceiptPreview';
import CompanyBillingReceipt from './CompanyBillingReceiptPreview';
import AdminAnnualFeeNotifyPreview from './AdminAnnualFeeNotifyPreview';

function PrintPreview(props) {
  const params = qs.parse(props.location.search);
  const {target, query} = params;

  const {collection, id, type, queryParams} = params;
  const [categoryDisplayMap] = useOutlet('categoryDisplayMap');

  const [isFetching, setIsFetching] = React.useState(true);
  const [record, setRecord] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [annualFeeNotifyRecords, setAnnualFeeNotifyRecords] = React.useState(
    null,
  );

  React.useEffect(() => {
    if (target === 'annualFeeNotify' && !annualFeeNotifyRecords) {
      async function fetchAnnualFeeNotify(queryParams) {
        try {
          setIsFetching(true);
          AppActions.setLoading(true);

          const adminToken = window.localStorage.getItem('admin-token');

          setAnnualFeeNotifyRecords(
            await AppActionsEx.adminPostAnnaulFeeNotify(
              queryParams,
              adminToken,
            ),
          );
        } catch (err) {
          console.warn(err);
        } finally {
          setIsFetching(false);
          AppActions.setLoading(false);
        }
      }

      function restructureAnnualNotify(queryParams) {
        setAnnualFeeNotifyRecords([{...queryParams.custom_data}]);
        setIsFetching(false);
      }

      const queryParams = JSON.parse(query);

      if (!queryParams.has_email_data) {
        fetchAnnualFeeNotify(queryParams);
      } else {
        restructureAnnualNotify(queryParams);
      }
    }
  }, [target, query, annualFeeNotifyRecords]);

  //TODO: need refactor, control render and trigger useEffect cycle.
  React.useEffect(() => {
    async function fetchRecordById(collection, id) {
      try {
        setIsFetching(true);
        AppActions.setLoading(true);

        const adminToken = window.localStorage.getItem('admin-token');
        setRecord(
          await JStorage.fetchOneDocument(collection, {id}, null, {
            token: adminToken,
          }),
        );
      } catch (err) {
        console.warn(err);
        message.error('發生錯誤');
      } finally {
        setIsFetching(false);
        AppActions.setLoading(false);
      }
    }

    async function fetchOrderExportRecords(queryParamsStr) {
      try {
        setIsFetching(true);
        AppActions.setLoading(true);

        const token = window.localStorage.getItem('admin-token');
        const queryParams = JSON.parse(queryParamsStr);

        const fetchFunc = (() => {
          const {qType} = queryParams;

          if (
            qType === '入會費' ||
            qType === '常年會費' ||
            qType === '跨區會費'
          ) {
            return AppActionsEx.getOrderExportByFee;
          } else if (qType === '課程' || qType === '活動') {
            return AppActionsEx.getOrderExportByCourse;
          } else if (qType === '藍新金流') {
            return AppActionsEx.getOrderExportByNeweb;
          }
        })();

        let ordersResp = await fetchOrdersByPagination({
          fetchFunc,
          queryParams,
          token,
        });

        if (!ordersResp?.total) {
          message.error('查無紀錄');
          return;
        }

        setRecords(ordersResp.results);
        setTotal(ordersResp.total);
      } catch (err) {
        console.warn(err);
        message.error('發生錯誤');
      } finally {
        setIsFetching(false);
        AppActions.setLoading(false);

        message.destroy();
      }
    }

    if (type === 'export' && !records) {
      if (Object.keys(categoryDisplayMap).length > 1) {
        fetchOrderExportRecords(queryParams);
      }
    }

    if (collection && id && !record) {
      fetchRecordById(collection, id);
    }
  }, [type, queryParams, collection, id, record, records, categoryDisplayMap]);

  if (isFetching) {
    return (
      <Wrapper>
        <div className="center-container">
          <Spin
            indicator={<LoadingOutlined spin style={{fontSize: 48}} />}
            size="large"
          />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {(() => {
        if (collection === 'product') {
          switch (type) {
            case 'pay':
              return (
                <div className="backdrop">
                  <ProductPayPreview record={record} />
                </div>
              );
            case 'consent':
              return (
                <div className="backdrop">
                  <ProductConsentPreview record={record} />;
                </div>
              );
            default:
              return null;
          }
        }

        if (collection === 'order' || collection === 'checkout') {
          switch (type) {
            case 'receipt':
              return (
                <div className="backdrop">
                  <OrderReceiptPreview record={record} />;
                </div>
              );

            case 'receipt-discount-item':
              return (
                <div className="backdrop">
                  <DiscountItemReceiptPreview
                    record={record}
                    index={params.index}
                    staff={params.staff ? JSON.parse(params.staff) : {}}
                    user={params.user ? JSON.parse(params.user) : {}}
                  />
                </div>
              );

            case 'payment-code':
              return (
                <div className="backdrop">
                  <OrderPaymentCodePreview record={record} />
                </div>
              );

            case 'export':
              return (
                <OrderExportPreview
                  queryParams={queryParams ? JSON.parse(queryParams) : {}}
                  total={total}
                  records={records}
                />
              );

            default:
              return null;
          }
        }

        if (collection === 'company_billing') {
          return <CompanyBillingReceipt record={record} />;
        }

        if (target === 'annualFeeNotify') {
          return (
            <AdminAnnualFeeNotifyPreview
              params={params}
              annualFeeNotifyRecords={annualFeeNotifyRecords}
            />
          );
        }
      })()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0;
  padding: 0;

  & .center-container {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .backdrop {
    border: 1px solid #fafafa;
    background-color: #fafafa;
  }

  & .page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  @page {
    size: A4 portrait;
    margin: 0 auto;
  }

  @media print {
    .sidebar {
      display: none;
    }

    html,
    body {
      width: 210mm;
      height: 297mm;
    }
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;

      :last-child {
        page-break-after: avoid;
      }
    }
  }
`;

export default withPageEntry(PrintPreview);
