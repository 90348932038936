import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

function DiscountItemReceiptPreview(props) {
  const [config] = useOutlet('config');
  const {record, index, staff, user} = props;

  return (
    <Wrapper>
      {record && (
        <div className="page">
          <div className="sender">
            <p>100 台北市中正區羅斯福路一段7號9樓</p>
            <p>
              <span className="fake" />
              社團法人台北律師公會
            </p>
            <p>
              <span className="fake" />
              Tel：(02) 23515071
            </p>
          </div>
          <div className="receiver">
            <h3>
              <label>地址：</label>
              <span>
                {user.zip} {user.city}
                {user.detail}
              </span>
            </h3>
            <h3>
              <label>收件人：</label>
              <span>{user.name} 律師 收</span>
            </h3>
          </div>
          <div className="divider" />
          <div className="first">
            <div className="title">社團法人台北律師公會 收據</div>
            <div className="content">
              <p className="field">
                <label>編號：</label>
                <span>{record.order_id || `${record.order_number}*`}</span>
              </p>
              <p className="field">
                <label>日期：</label>
                <span>{record.discount_items[index].paid_date}</span>
              </p>
              <p className="field">
                <label>律師大名：</label>
                <span>{user.name} 律師</span>
              </p>
              <p className="field">
                <label>金額：</label>
                <span>{record.discount_items[index].amount} 元</span>
              </p>
              <p className="field">
                <label>內容：</label>
                <span>{record.discount_items[index].name}</span>
              </p>
              <div className="contact">
                <div>
                  <label>社團法人台北律師公會</label>
                  <label>統一編號：78379129</label>
                </div>
                <div className="have-padding-left">
                  <label>地址： 100 台北市中正區羅斯福路一段7號9樓</label>
                </div>
                <div className="have-padding-left">
                  <label>電話：（02）23515071</label>
                  <label>傳真：（02）23913714</label>
                </div>
                <div>
                  <label>理事長：{config?.chairman}</label>
                  <label>經手人：{staff.name}</label>
                </div>
              </div>
            </div>
            <div className="note">第一聯：收執聯</div>
          </div>
          <div className="divider" />
          <div className="second">
            <div className="title">社團法人台北律師公會 收據</div>
            <div className="content">
              <p className="field">
                <label>編號：</label>
                <span>{record.order_id || `${record.order_number}*`}</span>
              </p>
              <p className="field">
                <label>日期：</label>
                <span>{record.discount_items[index].paid_date}</span>
              </p>
              <p className="field">
                <label>律師大名：</label>
                <span>{record.buyer_name} 律師</span>
              </p>
              <p className="field">
                <label>金額：</label>
                <span>{record.discount_items[index].amount} 元</span>
              </p>
              <p className="field">
                <label>內容：</label>
                <span>{record.discount_items[index].name}</span>
              </p>
              <div className="contact">
                <div>
                  <label>社團法人台北律師公會</label>
                  <label>統一編號：78379129</label>
                </div>
                <div className="have-padding-left">
                  <label>地址： 100 台北市中正區羅斯福路一段7號9樓</label>
                </div>
                <div className="have-padding-left">
                  <label>電話：（02）23515071</label>
                  <label>傳真：（02）23913714</label>
                </div>
                <div>
                  <label>理事長：{config?.chairman}</label>
                  <label>經手人：{staff.name}</label>
                </div>
              </div>
            </div>
            <div className="note">第二聯：公會存根聯</div>
          </div>
          <div className="divider" />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .page {
    & > .sender {
      margin-bottom: 30px;

      & > p > span {
        visibility: hidden;
        padding-left: 28px;
      }
    }

    & > .receiver {
      margin-bottom: 20px;
      font-size: 1rem;
      text-align: center;

      & > h3 > label {
        width: 80px;
      }

      & > h3 > p {
      }
    }

    & > .first {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    & > .second {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  & .divider {
    border-bottom: 1px dashed #000000;
  }

  & .title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.2rem;
  }

  & .content {
    margin-bottom: 10px;
    line-height: 30px;
    font-size: 14px;
  }

  & .contact > div > label {
    margin-right: 35px;
  }

  & .have-padding-left {
    padding-left: 15px;
  }

  & .field {
    & > label {
      width: 80px;
    }
  }

  & .note {
    text-align: center;
  }
`;

export default DiscountItemReceiptPreview;
